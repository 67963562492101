/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */
const wrapWithProvider = require("./wrap-with-provider").default
const wrapConsentBanner = require("./wrap-consent-banner").default
const { setupConsent } = require("./src/utils/googletag-manager")
const ReactDOM = require("react-dom/client")

export const wrapRootElement = wrapWithProvider

export const wrapPageElement = wrapConsentBanner

// 🔨 handle React errors #418 and #423 on /projects/ page
export const replaceHydrateFunction = () => {
  return (element, container) => {
    const root = ReactDOM.createRoot(container)
    root.render(element)
  }
}

export const onClientEntry = () => {
  setupConsent()
}
