const React = require("react")
const Bugsnag = require("@bugsnag/js").default
const BugsnagPluginReact = require("@bugsnag/plugin-react").default
const { Provider } = require("react-redux")
const createStore = require("./configure-store").default

Bugsnag.start({
    apiKey: process.env.GATSBY_BUGSNAG_API_KEY || "no-key-defined",
    plugins: [new BugsnagPluginReact()],
    releaseStage: process.env.GATSBY_ENV,
    enabledReleaseStages: ["production", "staging"],
})

const ErrorBoundary = Bugsnag.getPlugin("react").createErrorBoundary(React)

export default ({ element }) => {
    const store = createStore()

    return (
        <ErrorBoundary>
            <Provider store={store}>{element}</Provider>
        </ErrorBoundary>
    )
}
