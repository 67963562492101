const React = require("react")
const ConsentBanner = require("./src/components/ConsentBanner").default

export default ({ element }) => <>
    {element}
    <ConsentBanner />
</>



